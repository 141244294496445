
.adminPortfolio .error {
    color: red;
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
}

ul.documentList {
    font-size: 1.2rem;
    padding: 0.7rem;
    border: 1px solid var(--color-light);
    border-radius: 4px;
}

.documentList li {
    cursor: pointer;
}

.documentList li:hover {
    color: var(--color-dark);
}
