
.downloadPage {
    text-align: center;
}

.appsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    justify-items: center;
    max-width: 1200px;
    column-gap: 2rem;
    row-gap: 3rem;
    margin: 2rem auto;
}

@media screen and (max-width: 1100px) {
    .appsContainer {
        grid-template-columns: repeat(2, minmax(300px, 1fr));
        column-gap: 1.5rem;
    }

    .appsContainer > :only-child {
        grid-column: 1 / -1; /* Makes the single item span both columns */
        justify-self: center; /* Centers the item within the spanned columns */
    }
}
