/* RotationMessage.css */

.rotate-message {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    font-size: 1.5rem;
    z-index: 1000;
    justify-content: center;
    align-items: center;
}

.rotate-message p {
    margin: 0;
}
