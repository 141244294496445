:root  {
    --color-portfolio_theme: #2F80ED;
    --color-portfolio_theme_strong: #185ABD;
    --color-portfolio_background: #fdfdfd;
    --color-portfolio_header_background: #ffffff;
    --color-portfolio_text: #666;
    --color-portfolio_text_strong: #333;
}

.portfolio {
    font-family: 'Arial', sans-serif;
    font-size: 1.4rem;
    margin: 0;
    padding: 0;
    background-color: var(--color-portfolio_background);
}

/* The wrapper takes up the remaining space */
.portfolio .wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
}

/* The content will grow to fill the space before the footer */
.portfolio .container {
    flex: 1;
    padding: 2rem 4rem;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    width: inherit;
}


/* Reset from index.css */
::-webkit-scrollbar.portfolio  {
    width: 0;
    height: 0;
}

.portfolio a:link,
.portfolio a:visited {
    font-weight: 400;
}

@media screen and (max-width: 1100px) {
    .portfolio .container {
        flex-direction: column;
    }
}