.markdown-content {
    width: inherit;
}

.markdown-content div.card {
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    box-shadow: 0 0 0 transparent;
    color: var(--color-portfolio_text);
}

.markdown-content div.card table {
    border-collapse: collapse;
    width: auto;
    margin: 1em 0;
    text-align: left;
    background-color: #f9f9f9;
}

.markdown-content div.card th, .markdown-content div.card td {
    border: 1px solid #ddd; /* Light border for cells */
    padding: 12px; /* Padding for readability */
}

.markdown-content div.card th {
    background-color: #f4f4f4; /* Light gray background for header */
    color: #333; /* Darker text color for contrast */
    font-weight: bold; /* Bold header text */
}

/* clearfix */
.markdown-content div.card:after {
    content:" ";
    display:block;
    clear:both;
}

.markdown-content div.card.portfolio-card {
    display: block;
}

.markdown-content div.card:not(:last-child) {
    margin-bottom: 6rem;
}

.markdown-content div.card-image {
    width: 260px;
    float: right;
    padding: 0 0 2rem 2rem;
}

.markdown-content div.card-image img {
    width: 100%;
    height: auto;

    border-radius: 2px;
    box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.3); /* Shadow effect */
    border: 1px solid rgba(0, 0, 0, 0.08);
}

.markdown-content div.card p {
    font-size: 1.8rem;
    line-height: 1.6;
    margin-bottom: 1.8rem;
}

.markdown-content div.card p:last-child {
    margin-bottom: 0;
}

.markdown-content div.card strong,
.markdown-content div.card em {
    color: var(--color-portfolio_theme_strong);
    font-weight: 600;
}

.markdown-content div.card a {
    color: var(--color-portfolio_theme);
}

.markdown-content div.card a:hover {
    text-decoration: none;
    border-bottom: 2px solid var(--color-portfolio_theme_strong);
}

.markdown-content div.portfolio-card ul {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.markdown-content div.portfolio-card.col2 ul {
    grid-template-columns: repeat(2, 1fr);
}

.markdown-content div.portfolio-card.col1 ul {
    grid-template-columns: 1fr
}

.markdown-content div.portfolio-card.col1 {
    width: 80%;
    margin: auto;
}

.markdown-content div.portfolio-card ul div,
.markdown-content div.portfolio-card ul li,
.markdown-content div.portfolio-card ul img {
    height: 100%;
    width: 100%;
}

.markdown-content div.portfolio-card ul li div div {
    overflow: hidden;
}

.markdown-content div.portfolio-card ul li img {
    object-fit: cover;
    display: block;
    transition: transform 0.3s ease;
}

.markdown-content div.portfolio-card ul li:hover img {
    transform: scale(1.1);
}

.markdown-content div.two-columns {
    display: block;
    column-count: 2;
    column-gap: 3rem;
    column-rule: 1px solid #ccc;
}

.markdown-content div.two-columns p {
    break-inside: avoid;
}

.markdown-content div.block {
    display: block;
}

@media screen and (max-width: 1100px) {

    .markdown-content div.card:not(:last-child) {
        margin-bottom: 4.5rem;
    }

    .markdown-content div.card h1 {
        font-size: 2.6rem;
    }

    .markdown-content div.card p {
        font-size: 1.4rem;
        margin-bottom: 2rem;
    }

    .markdown-content div.card-image {
        width: 200px;
    }
}
