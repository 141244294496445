
.contactContent {
    display: flex;
    align-items: center;
    padding: 4rem 2rem;
    gap:2rem;
}

.contactInfo {
    max-width: 800px;
    text-align: left;
    margin-bottom: 4rem;
    flex: 1;
    font-size: 1.6rem;
}

.contactInfo h2 {
    font-size: 3rem;
    color: var(--color-header-dark);
    margin-bottom: 1rem;
    font-weight: 800;
}

.contactInfo p {
    margin-bottom: 3rem;
}

.contactDetails {
    font-size: 1rem;
    line-height: 1.8;
}

.contactDetails p {
    margin: 1rem 0;
}

.contactMap {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem 0;
    flex: 1;
}

@media screen and (max-width: 1100px) {
    .contactInfo h2 {
        font-size: 2.6rem;
    }

    .contactInfo, .contactMap {
        font-size: 1.4rem;
    }
}
