
/* Root Variables */
:root {
    --color-header: #007bff;
    --color-header-dark: #0056b3;
    --box-shadow-large: 0 0 rem -1.5rem rgba(0, 0, 0, 0.29);
    --box-shadow-middle: 0 0 1rem rgba(0, 0, 0, 0.1);
}

.inline-banner {
    position: relative;
    text-align: center;
    color: var(--color-dark--1);
    background-color: #D3E7F2FF;
    display: flex;
    justify-content: flex-end;
}

.bannerImage {
    height: 20rem;
    object-fit: contain;
}

.inline-banner h1 {
    position: absolute;
    top: 50%;
    left: 4rem;
    transform: translate(0, -50%);
    font-size: 4.4rem;
    margin: 0;
    color: var(--color-dark--2);
}

.icon {
    display: block;
    font-size: 4rem;
    color: var(--color-dark--1);
}

.inline-section {
    overflow: auto;
    color: var(--color-dark--2);
    line-height: 1.6;
    margin: 0;
    padding: 0;
}

.inline-content {
    padding: 4rem;
}

.inline-content h2,
.inline-content h3 {
    margin-top: 1rem;
    font-size: 3rem;
    font-weight: 800;
    color: var(--color-header);
    border-bottom: 2px solid var(--color-header);
    padding-bottom: 0;
    margin-bottom: 2rem;
    display: inline-block;
}

.inline-content h3 {
    font-size: 2rem;
    color: var(--color-header);
    border-bottom: none;
}


.inline-content p {
    margin-bottom: 3rem;
    font-size: 1.6rem;
}

.inline-popup div {
    width: 30vw;
    color: var(--color-dark--1);
}

.inline-popup h2 {
    color: var(--color-header-dark);
    font-size: 3rem;
    font-weight: 800;
    margin-bottom: 2rem;
}

.inline-popup div {
    font-size: 1.6rem;
}

.inline-form button {
    padding: 1rem 2rem;
    margin: 1rem 0.5rem;
    border: none;
    background-color: #007BFF;
    color: white;
    font-size: 1.4rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    width: fit-content;
}

.inline-form .submit button {
    background-color: #00c311;
}

.inline-form .submit button:not(:disabled):hover {
    background-color: #03a111;
}

.inline-form .warning button {
    background-color: #e84848;
}

.inline-form .warning button:not(:disabled):hover {
    background-color: #b33939;
}

.inline-form button:not(:last-child) {
    margin-right: 2rem;
}

.inline-form button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.inline-form button:not(:disabled):hover {
    background-color: #0056b3;
}

.inline-form span.input {
    padding: 1rem 2rem;
}

.inline-form .error {
    color: red;
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 1100px) {
    .bannerImage {
        height: 15rem;
    }

    .inline-banner h1 {
        font-size: 3.6rem;
    }

    .inline-content h2, .inline-content h3 {
        font-size: 2.6rem;
    }

    .inline-content p {
        font-size: 1.4rem;
    }
}
