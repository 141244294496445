
.mission:hover :global(.icon),
.vision:hover :global(.icon) {
    color: var(--color-dark);
}

.missionVision {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-bottom: 3rem;
}

.missionVision .mission,
.missionVision .vision {
    background-color: #fdfdfd;
    padding: 2.5rem;
    box-shadow: var(--box-shadow-large);
    border-radius: 6px;
}

.missionVision .mission h2,
.missionVision .vision h2 {
    color: var(--color-header-dark);
}

.missionVision .mission p,
.missionVision .vision p {
    font-size: 1.7rem;
    line-height: 1.8;
    margin: 0;
}

@media screen and (max-width: 1100px) {
    .missionVision .mission, .missionVision .vision {
        padding: 1.5rem;
    }

    .missionVision .mission p, .missionVision .vision p {
        font-size: 1.5rem;
    }
}