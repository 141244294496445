
.largeContainer {
    margin: 0 auto;
    grid-template-rows: auto 1fr;
    display: grid;
    gap: 2rem;
    padding: 0;
    font-size:1.4rem;
    max-width: none;
    min-height: 50vh;
    background-color: white;
}

.largeContainer em {
    color:var(--color-dark);
    font-weight: normal;
}