
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* High z-index to ensure it's on top of other elements */
}

.modalContent {
    background-color: white;
    padding: 2rem;
    border-radius: 4px;
    box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.3);
    z-index: 1001; /* Ensure the content is above the overlay */
}

.buttonsContainer {
    margin-top: 2rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}


