
.loginContainer {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: var(--box-shadow-middle);
    width: 32rem;
    text-align: center;
    margin: 8rem auto;
}

.icon {
    color: #5c93c5;
    margin-bottom: 2rem;
}

.inputContainer {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    border: 1px solid #b3c0d1;
    border-radius: 5px;
    padding: 1rem;
    background-color: #f7f9fc;
}

.inputIcon {
    margin-right: 1rem;
    color: #5c93c5;
}

.loginButton {
    background-color: #418bca;
    color: white;
    border: none;
    padding: 1rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.6rem;
    width: 100%;
    transition: background-color 0.3s;
}

.loginButton:hover {
    background-color: #3578a7;
}

.loginForm input {
    border: none;
    outline: none;
    flex: 1;
    font-size: 1.6rem;
    background-color: transparent;
}

p.errMessage {
    color: red;
    margin-bottom: 1rem;
}

.link {
    color: #418bca;
    cursor: pointer;
    font-size: 1.2rem;
}