

.legalNotices :global(#terms-of-service),
.legalNotices :global(#privacy-policy) {
    background-color: #fdfdfd;
    padding: 2.5rem;
    box-shadow: var(--box-shadow-large);
    border-radius: 6px;
}

div.noticeTitle {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.noticeTitle .icon {
    font-size: 4rem;
    color: var(--color-header-dark);
    margin-top: -1rem;
}

.noticeTitle h2  {
    color: var(--color-header-dark);
}

.legalNotices p, .legalNotices ul {
    font-size: 1.7rem;
    line-height: 1.8;
    margin: 0;
}

.legalNotices ul {
    margin-left: 20px;
    list-style: disc;
}

.legalNotices a:link,
.legalNotices a:visited {
    font-weight: normal;
}

.legalNotices a:hover {
    text-decoration: none;
    color: var(--color-anchor);
    border-bottom: 2px solid var(--color-anchor);
}


