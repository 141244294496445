

.videoWrapper {
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0.4rem 0.8rem #0003;
    background-color: var(--color-dark--2);
    width: 80vw;
    height: 45vw;
    max-width: 800px;
    max-height: 450px;
    margin: auto;
}

.controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);
    padding: 1rem;
    opacity: 1;
    pointer-events: all;
    transition: opacity 2s ease;
}

.controls.hidden {
    opacity: 0;
    pointer-events: none;
}

.controls button {
    background: none;
    border: none;
    color: white;
    padding: 1rem;
    cursor: pointer;
    font-size: 1.6rem;
}

.controls button:hover {
    color: var(--color-dark);
}

.controls input[type='range'] {
    margin: 0 1rem;
    cursor: pointer;
}

.slider {
    -webkit-appearance: none;
    width: 100px;
    height: 0.8rem;
    background: #ddd;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
    border-radius: 0.5rem;
    margin: 0 1rem;
    padding: 0;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.6rem;
    height: 1.6rem;
    background: var(--color-dark);
    cursor: pointer;
    border-radius: 50%;
}

.slider::-moz-range-thumb {
    width: 1.6rem;
    height: 1.6rem;
    background: var(--color-dark);
    cursor: pointer;
    border-radius: 50%;
}