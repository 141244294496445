
.mission:hover .icon,
.vision:hover .icon {
    color: var(--color-dark);
}

 .portfolioList {
    background-color: #fdfdfd;
    padding: 2.5rem;
    box-shadow: var(--box-shadow-large);
    border-radius: 6px;
}

.portfolioList input {
    width: 25rem;
    padding: 1rem 2rem;
}

.content h2 {
    color: var(--color-header-dark);
    border-bottom: none;
}