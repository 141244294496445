/* Minimalist CSS for the SortableTable */

.sortableTable table {
    width: 100%;
    border-collapse: collapse;
    margin: 2rem 0;
    font-size: 1.6rem;
    font-family: Arial, sans-serif;
    /* table-layout: fixed; /* Enforces consistent column widths */
}

.sortableTable th, .sortableTable td {
    padding: 1.2rem 1.5rem;
    border: 1px solid #ddd;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sortableTable th {
    cursor: pointer;
    background-color: #f4f4f4;
}

.sortableTable th:hover {
    background-color: #e9e9e9;
}

.sortableTable tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.sortableTable tbody tr:hover {
    background-color: #f1f1f1;
}

.sortableTable button {
    padding: 1rem 2rem;
    margin: 1rem 0.5rem;
    border: none;
    background-color: #007BFF;
    color: white;
    font-size: 1.4rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.sortableTable button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.sortableTable button:not(:disabled):hover {
    background-color: #0056b3;
}

.sortableTable span {
    margin: 0 1rem;
    font-size: 1.4rem;
    color: #555;
}

@media screen and (max-width: 1100px) {
    .sortableTable table {
        font-size: 1.4rem;
    }

    .sortableTable button {
        padding: 0.8rem 1.8rem;
    }
}