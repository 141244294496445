
.footer {
    background-color: var(--color-dark--0);
    color: #fff;
}

.footer em {
    color: var(--color-dark);
    font-weight: normal;
}

.footerContent {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.footerSection {
    margin: 2rem;
    flex: 1;
    min-width: 250px;
    font-size: 1.2rem;
}

.footerSection h4 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.footerSection p,
.footerSection ul {
    margin: 0 0 1rem;
}

.footerSection ul {
    list-style-type: none;
    padding: 0;
}

.footerSection ul li {
    margin-bottom: 1rem;
}

.footerSection ul li a {
    color: #fff;
    text-decoration: none;
}

.footerSection ul li a:hover {
    text-decoration: underline;
}

.footerSection ul li a:global(.active) {
    color: var(--color-dark);
    cursor: default;
    pointer-events: none;
}

.footerBottom {
    text-align: center;
    padding: 1rem 0;
    border-top: 1px solid #444;
    font-size: 1.2rem;
}

.footerBottom p {
    margin: 0;
}

@media screen and (max-width: 1100px) {
    .quickLinks {
        display: none;
    }
}
