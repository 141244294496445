.carousel {
    position: relative;
    margin: auto;
    overflow: hidden;
    width: 80vw;
    height: 45vw;
    max-width: 800px;
    max-height: 450px;
    border-radius: 3px;
    box-shadow: 0 .4rem 0.8rem #0003;
    background-color: var(--color-dark--2);
}

.carouselImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carouselImage img {
    width: inherit;
}


.carouselImage.active {
    opacity: 1;
}

.carouselIndicators {
    position: absolute;
    bottom: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
}

.dot {
    height: 1.5rem;
    width: 1.5rem;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    cursor: pointer;
}

.dot.active {
    background-color: #717171;
}

button.prev, button.next {
    height: 100%;
    position: absolute;
    padding: 1.6rem;
    color: white;
    font-weight: bold;
    font-size: 1.8rem;
    transition: 0.6s ease;
    -webkit-user-select: none;
    user-select: none;
    border: none;
    background-color:  var(--color-light--3);
}

button.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

button.prev:hover, button.next:hover {
    background-color: var(--color-light--1);
}

.carouselCaption {
    position: absolute;
    top: 1rem;
    left: 6rem;
    font-size: 1.4rem;
    color: var(--color-dark--2);
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.8);
}