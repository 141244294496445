.hover-container {
    position: relative;
    display: inline-block;
}

.input-big > span.hover-container {
    width: 100%;
}

.caption {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    z-index: 99;
    color: var(--color-dark);

    font-size: 1rem;
    background-color: #FFF;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    padding: 2px 12px;
    margin-top: 5px;
    white-space: nowrap;
    transition: visibility 0.3s, opacity 0.3s linear 0.3s;
}

.hover-container:hover .caption {
    visibility: visible;
    opacity: 1;
}

