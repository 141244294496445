
.mainContent {
    display: flex;
    align-items: flex-start;
    margin-top: 4rem;
    column-gap: 4rem;
    width: 100%;
}

.mainContent h1 {
    font-size: 3.6rem;
    color: var(--color-portfolio_text_strong);
    margin-bottom: 3rem;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/* Special styles : rotate-content & photo-content  */

:global(.rotate-content) :global(.content-style) {
    transform: rotate(-2deg);
    background-color: var(--color-portfolio_theme);
    border-radius: 2px;
}

:global(.rotate-content) :global(.content-style) > li div {
    transform: rotate(2deg);
}

:global(.rotate-content) :global(.content-style) > div {
    transform: rotate(2deg);
}

:global(.photo-content) :global(.content-style) {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.4rem 0.8rem #0003;
}

:global(.photo-content) :global(.content-style) img,
:global(.photo-content) :global(.content-style) > div {
    box-shadow: none !important;
}

@media screen and (max-width: 1100px) {
    .mainContent {
        margin-top: 2rem;
    }

    .mainContent h1 {
        font-size: 2.6rem;
    }

    :global(.photo-content) :global(.content-style) {
        padding: 1.6rem;
    }
}