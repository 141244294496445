
.appCard {
    background-color: #fdfdfd;
    border: 1px solid var(--color-light--2);
    width: 35rem;
    border-radius: 8px;
    box-shadow: var(--box-shadow-large);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s, box-shadow 0.3s;

}


.appCard:hover {
    transform: translateY(-5px);
    box-shadow: var(--box-shadow-middle);
}

.appScreenshot {
    width: 15rem;
    height: auto;
    align-self: center;
    margin : 1rem;
    border-radius: 8px;
    border: 1px solid var(--color-light--1);
}

.appContent {
    padding: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.appContent h3 {
    margin-top: 0;
    color: var(--color-header-dark);
    text-transform: uppercase;
    font-size: 2rem;
}

.appContent p {
    flex: 1;
    margin-bottom: 20px;
    text-align: left;
    font-size: 1.2rem;
}

.appActions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.appStoreBadge {
    width: 150px;
    height: auto;
    transition: transform 0.3s;
}

.appStoreBadge:hover {
    transform: scale(1.05);
}



