/* Header navigation */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 4rem;
    background-color: var(--color-portfolio_header_background);
    box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.05);
}

.header .logo {
    font-size: 2.4rem;
    font-weight: bold;
    color: var(--color-portfolio_theme);
}

.header div.logo span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color:white;
    background-color: var(--color-portfolio_theme);
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
}

.header nav ul {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 2rem;
}


.header nav ul li a {
    color: var(--color-portfolio_text_strong);
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 500;
    cursor: pointer;
}

.header nav ul li a:hover {
    border-bottom: 0.2rem solid var(--color-portfolio_theme);
}

.header nav ul li a:global(.disabled) {
    color: var(--color-portfolio_theme_strong);
    border-bottom: 0.2rem solid var(--color-portfolio_theme_strong);
    cursor: default;
    pointer-events: none;
}

@media screen and (max-width: 1100px) {
    .header {
        flex-direction: column;
    }

    .header .logo {
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    .header nav ul li a {
        font-size: 1.4rem;
    }

    .header div.logo span {
        width: 3rem;
        height: 3rem;
    }
}