.item {
    display: inline-block;
    border-radius: 6px;
    border: 1px solid var(--color-light--2);
    color: var(--color-dark--1);
    padding: 1rem 2rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: transform 0.1s ease;
}

.item:hover {
    border: 1px solid var(--color-light--1);
    transform: scale(1.05);
}

.item.vip {
    background-color: var(--color-light);
    border: 1px solid var(--color-light);
}

.item.vip:hover {
    border: 1px solid var(--color-medium);
}

@media screen and (max-width: 1100px) {

    .item {
        padding: 0.8rem 1.2rem;
    }
}