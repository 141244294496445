
.portfolioCreationContainer {
}

.formGroup input[type= "text"],
.formGroup input[type="email"],
.formGroup input[type="url"],
.formGroup textarea,
.formGroup select,
.formGroup input[type="file"] {
    display: inline-block;
    width: 100%;
    text-align: left;
    padding: 1rem 1rem;
}

.formGroup select.h3 {
    font-size: 2rem;
    color: var(--color-header);
    font-weight: 800;
    padding: 0 0 1rem 0;
    border:0;
}

.formGroup textarea {
    height: 20rem;
}

.portfolioForm {
    display: flex;
    flex-direction: column;
}

.portfolioForm .errorText {
    color: red;
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
}

.formGroup input.inputError,
.formGroup textarea.inputError {
    border: 1px solid  var(--color-dark);
}

.formGroup button {
    margin: 0;
}

.formGroup .inputError button {
    color:var(--color-dark)
}

.formGroup {
    margin-bottom: 1.5rem;
}

.formGroup label {
    display: block;
    margin-bottom: 0.5rem;
}

.formGroup .imagePreview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f1f1f1;
    padding: 1rem;
    margin-bottom: 0.5rem;
}

.formGroup .removeBtn {
    background-color: red;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 4px;
}
